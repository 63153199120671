import React, { useEffect } from 'react';
import styled from 'styled-components';

import IconCheckMark from 'images/icons/check-mark-1.svg';

const Container = styled.div`
  background-color: #f9e05f;
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  opacity: 0.8;
  padding: 10px 15px;
  box-sizing: border-box;
  width: 95vw;
  position: fixed;
  bottom: 82px;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  z-index: 950;
`;

const SuccessIcon = styled.div`
  width: 16px;
  height: 16px;
`;

const Message = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #3b3516;
`;

const Alert = (props) => {
  const { message, show, onClose } = props;

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  }, [show]);

  return (
    show && (
      <Container>
        <SuccessIcon>
          <img src={IconCheckMark} />
        </SuccessIcon>

        <Message>{message}</Message>
      </Container>
    )
  );
};

export default Alert;
